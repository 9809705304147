import React, {Component} from 'react';
import {connect} from 'react-redux';
import $ from 'jquery';
import LDH from '../helpers/LeopardDataHelper';
import LRH from '../helpers/LeopardReactHelper';
import LPH from '../helpers/LeopardPermissionHelper';
import {TextBox} from 'devextreme-react';
import LeopardLoginUserInfoPanel from '../components/LeopardLoginUserInfoPanel';
import LeopardStaticUIConfig from "../foundation/LeopardStaticUIConfig";
import {Button as TextBoxButton} from "devextreme-react/text-box";
import {SetLeopardAdminMode} from "../foundation/LeopardActionCreators";

class LeopardTopMenuBar extends Component {
    // =======================================================================
    // ============================ Constructor ==============================
    // =======================================================================
    constructor(props) {
        super(props);

        this.state = {
            showLeftMenuContent: true
        };
        this.uiObjectInstance = null;
    }

    // =======================================================================
    // ============================= UI Logic ================================
    // =======================================================================
    componentDidMount = () => {
        $(".leopard-loggedin-user-tag").off("click").on("click", function () {
            let $panel = $(".leopard-login-userinfo-panel");
            if ($panel.is(":visible")) {
                $panel.hide();
            } else {
                $panel.show();
            }
            return false;
        });
    };

    // =======================================================================
    // =========================== Event Handlers ============================
    // =======================================================================
    navHideButtonOnClick = (e) => {
        let that = this;
        if (this.state.showLeftMenuContent === false) {
            this.setState({showLeftMenuContent: true}, function () {
                that.props.navHideButtonOnClick(e);
            });
        } else {
            this.setState({showLeftMenuContent: false}, function () {
                that.props.navHideButtonOnClick(e);
            });
        }
    };

    searchBarOnValueChanged = () => {
        let that = this;
        if (LDH.IsObjectNull(that.uiObjectInstance)) return;

        let inputValue = that.uiObjectInstance.option("value");
        LeopardStaticUIConfig.Global_MenuItemSearchText = inputValue;
        LRH.SearchForMenuItems(inputValue);
    };

    addUIObjectInstance = (e) => {
        if (LDH.IsObjectNull(e) || LDH.IsObjectNull(e.instance)) {
            return;
        }
        this.uiObjectInstance = e.instance;
    };

    leopardLogoOnClick = () => {
        let that = this;
        let $logo = $(".leopard-top-logo");
        let count = $logo.attr("count");
        if (LDH.IsValueEmpty(count)) count = 0;
        count = parseInt(count);
        $logo.attr("count", count + 1);
        if (count > 10 && !LPH.IsLeopardAdminModeEnabled(that)) {
            that.props.SetLeopardAdminMode({enabled: true});
        }
    };

    // =======================================================================
    // ============================ UI Templates =============================
    // =======================================================================
    initializeTopMenuNavigationBar = () => {
        if (this.props.state.selectedLeftMenuItem === undefined ||
            this.props.state.selectedLeftMenuItem === null) {
            return (
                <React.Fragment>
                    <div className={"leopard-topmenu-bar-right"}>
                        <div className="leopard-navbar-title-text"></div>
                    </div>
                </React.Fragment>
            );
        }
        return (
            <React.Fragment>
                <div className={"leopard-topmenu-bar-right"}>
                    <div className="leopard-navbar-title-text">
                        <span style={{marginRight: "15px"}}>
                            {this.props.state.selectedLeftMenuItem.parentMenuItemName === "Dashboards" ?
                                "Workspaces" : this.props.state.selectedLeftMenuItem.parentMenuItemName}
                        </span>
                        {
                            !LDH.IsValueEmpty(this.props.state.selectedLeftMenuItem.parentMenuItemName) &&
                            !LDH.IsValueEmpty(this.props.state.selectedLeftMenuItem.menuItemName) ?
                                <span>/</span> : null
                        }
                        <span style={{marginLeft: "15px"}}>
                            {this.props.state.selectedLeftMenuItem.menuItemName}
                        </span>
                    </div>
                </div>
            </React.Fragment>
        );
    };

    initializeApplicationWelcomePanel() {
        return (
            <div style={{height: "50px"}}>
                <img className="leopard-top-logo" src="/css/images/LeopardLogoSmall.png" alt="Leopard Systems"
                     onClick={this.leopardLogoOnClick}/>
                <div className={"leopard-welcome-text"}>
                    Welcome <span style={{fontWeight: "bold"}}>{this.props.loggedInUserName}</span>
                </div>
                <div className={"leopard-loggedin-user-tag fas fa-user-circle"}></div>
            </div>
        );
    }

    initializeApplicationTopMenu() {
        return (
            <div id={"LeopardTopMenu"} className="leopard-top-menu-bar">
                {
                    this.state.showLeftMenuContent === false ? "" :
                        <div className={"leopard-topmenu-bar-left"}>
                            <TextBox className={"leopard-topmenu-search"} placeholder={"Search for views..."}
                                     valueChangeEvent={"keyup"}
                                     onValueChanged={(e) => this.searchBarOnValueChanged(e)}
                                     ref={(e) => this.addUIObjectInstance(e)} showClearButton={true}>
                                <TextBoxButton options={{
                                    icon: "topMenuItemSearchInput_MiniButton fas fa-search",
                                    type: 'default'
                                }} name={"topMenuItemSearchInput_MiniButton"} location={'after'}
                                ></TextBoxButton>
                            </TextBox>
                        </div>
                }
                <div className={"leopard-topmenu-bar-hidebutton fas " +
                    (this.state.showLeftMenuContent ? "fa-caret-left" : "fa-caret-right")}
                     onClick={(e) => this.navHideButtonOnClick(e)}></div>
                {this.initializeTopMenuNavigationBar()}
            </div>
        );
    }

    // =======================================================================
    // ============================ UI Rendering =============================
    // =======================================================================
    render() {
        return (
            <React.Fragment>
                {this.initializeApplicationWelcomePanel()}
                {this.initializeApplicationTopMenu()}
                <div className="leopard-login-userinfo-panel" style={{display: "none"}}>
                    <LeopardLoginUserInfoPanel/>
                </div>
            </React.Fragment>
        );
    }
}

// =======================================================================
// ========================== Redux Operations ===========================
// =======================================================================
const RetrieveDataFromReducer = (state) => {
    return {state};
};

const SendDataToReducer = (dispatch) => {
    return {
        SetLeopardAdminMode: (data) => {
            dispatch(SetLeopardAdminMode(data));
        }
    };
};

export default connect(RetrieveDataFromReducer, SendDataToReducer)(LeopardTopMenuBar);
