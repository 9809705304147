import React, {Component} from 'react';
import $ from 'jquery';
import {Button, FileUploader, TextBox} from 'devextreme-react';
import {SelectBox} from 'devextreme-react/select-box';
import LRH from "../helpers/LeopardReactHelper";
import {Popover} from "devextreme-react/popover";
import LDH from "../helpers/LeopardDataHelper";
import LeopardAjaxHelper from "../helpers/LeopardAjaxHelper";
import LeopardDropdownHelper from "../helpers/LeopardDropdownHelper";

class LeopardDocumentUploader extends Component {
    constructor(props) {
        super(props);

        this.state = {
            popupVisible: false,
            directory: "reference",
            fileName: "",
            fileContent: ""
        };
        this.uiObjectInstances = [];
        this.instanceInitialized = false;
        this.disposingAllInstances = false;
    }

    componentWillUnmount = () => {
        this.disposingAllInstances = true;
        LRH.DisposeUIInstancesFromList(this.uiObjectInstances);
    };

    addUIObjectInstance = (data, addToParent) => {
        this.uiObjectInstances[data.name] = data.instance;

        if (addToParent === true) {
            this.props.setComponentInstance({
                componentName: data.name,
                e: data.instance
            });
        }
    };

    popupOnHiding = () => {
        let instance = this.uiObjectInstances["documentFileSelector"];
        instance.instance.reset();
        this.setState({popupVisible: false});
    };

    popupOnShowing = (e) => {

    };

    popupOnShown = () => {
        let that = this;
        if (that.instanceInitialized === false) {
            that.instanceInitialized = true;
            LRH.AddDisposablePopupInstances(that);
        }
    };

    directoryOnValueChanged = (e) => {
        this.setState({directory: e.value});
    };

    fileNameOnValueChanged = (e) => {
        this.setState({fileName: e.value});
    };

    cancelUploadOnClick = (e) => {
        this.uiObjectInstances["documentUploaderControl"].instance.hide();
    };

    fileUpload = (e) => {
        let that = this;
        if (LDH.IsValueEmpty(this.state.directory) ||
            LDH.IsValueEmpty(this.state.fileName) ||
            LDH.IsValueEmpty(this.state.fileContent)) {
            LRH.ShowToast("Validation failed. The directory and file name cannot be blank.", "error", 5000);
            return;
        }

        try {
            let fileContentJSON = JSON.parse(this.state.fileContent);
            if (this.state.directory === "cc_dataview_config" &&
                (LDH.IsObjectNull(fileContentJSON["dataViewPersistentId"]) ||
                    LDH.IsObjectNull(fileContentJSON["documentEditorDataSchema"]) ||
                    LDH.IsObjectNull(fileContentJSON["documentEditorUISchema"]))) {
                LRH.ShowToast("Validation failed. Please select a valid schema document.", "error", 5000);
                return;
            }
        } catch (ex) {
            LRH.ShowToast("Validation failed. Please select a valid schema document.", "error", 5000);
            return;
        }

        $(".document-upload-popup-panel-container").addClass("shown");
        LeopardAjaxHelper.InsertOrUpdateDocumentToS3(JSON.parse(this.state.fileContent),
            this.state.fileName, this.state.directory, function () {
                LRH.ShowToast("Your document has been successfully uploaded.", "success", 5000);
                that.uiObjectInstances["documentUploaderControl"].instance.hide();
                $(".document-upload-popup-panel-container").removeClass("shown");
            }, function () {
                LRH.ShowToast("Failed to upload document.", "error", 5000);
                $(".document-upload-popup-panel-container").removeClass("shown");
            });
    };

    fileUploaderValueOnChanged = (e) => {
        let that = this;
        for (let i = 0; i < e.value.length; i++) {
            let reader = new FileReader();
            reader.onload = function (event) {
                let fileData = event.target.result;
                that.setState({
                    fileContent: fileData
                });
            };
            reader.readAsText(e.value[i]);
        }
    };

    render() {
        if (this.disposingAllInstances) return null;
        let dataViewId = this.props.dataViewId;

        return (
            <Popover target={"#GridView_TopBar_Upload_" + dataViewId} position="bottom" width={"auto"}
                     onHiding={(e) => this.popupOnHiding(e)} onShowing={(e) => this.popupOnShowing(e)}
                     ref={(e) => this.addUIObjectInstance({name: "documentUploaderControl", instance: e}, true)}
                     onShown={(e) => this.popupOnShown(e)} shading={false} animation={null}>

                <div className={"document-upload-popup-panel-container"}>
                    <span className="leopard-gridview-dataloading">
                        <i className="fas fa-spinner fa-pulse"
                           style={{color: "rgb(255, 128, 0)", fontSize: "30px"}}></i>
                    </span>
                </div>

                <div className={"document-upload-popup-panel-master-container"}>
                    <div style={{marginBottom: "10px"}}>
                        <div className={"leopard-settings-panel-content-label"}>Directory:</div>
                        <div>
                            <SelectBox displayExpr={'name'} valueExpr={'id'}
                                       defaultValue={this.state.directory}
                                       dataSource={LeopardDropdownHelper.DropdownSelectionDataGridDocumentDirectory}
                                       onValueChanged={(e) => this.directoryOnValueChanged(e)}>
                            </SelectBox>
                        </div>
                    </div>
                    <div style={{marginBottom: "10px"}}>
                        <div className={"leopard-settings-panel-content-label"}>File name:</div>
                        <div style={{display: "flex", flexDirection: "row"}}>
                            <TextBox value={this.state.fileName} width={"100%"}
                                     onValueChanged={this.fileNameOnValueChanged}>
                            </TextBox>
                            <div style={{padding: "5px"}}>.json</div>
                        </div>
                    </div>
                    <div style={{marginBottom: "0px"}}>
                        <div className={"leopard-settings-panel-content-label"}>Select a file to upload:</div>
                        <div>
                            <FileUploader
                                width={220} multiple={false} uploadMode={"useButtons"}
                                ref={(e) => this.addUIObjectInstance({
                                    name: "documentFileSelector",
                                    instance: e
                                }, true)}
                                onValueChanged={(e) => this.fileUploaderValueOnChanged(e)}
                                accept={'.json'}></FileUploader>
                        </div>
                    </div>
                    <div style={{marginBottom: "10px", display: "flex", flexDirection: "row"}}>
                        <div style={{width: "100%", marginRight: "10px"}}>
                            <Button text={"Upload"} className={"leopard-standard-button"} width={"100%"}
                                    onClick={(e) => this.fileUpload({e: e})}></Button>
                        </div>
                        <div style={{width: "100%"}}>
                            <Button text={"Cancel"} className={"leopard-standard-button"} width={"100%"}
                                    onClick={(e) => this.cancelUploadOnClick({e: e})}></Button>
                        </div>
                    </div>
                </div>
            </Popover>
        )
    }
}

export default LeopardDocumentUploader;
