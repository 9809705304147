export const InitChartData = (data, id) => {
    return {
        type: "INIT_CHART_DATA",
        chartData: data,
        dataViewId: id
    }
};

export const SetWebAppInitialized = (data) => {
    return {
        type: "SET_WEBAPP_INITIALIZED",
        webAppInitialized: data
    }
};

export const SetAuthenticatedUser = (data) => {
    return {
        type: "SET_AUTHENTICATED_USER",
        authenticatedUser: data
    }
};

export const InitCustomStore = (store, id) => {
    return {
        type: "INIT_CUSTOM_STORE",
        customStore: store,
        gridViewId: id
    }
};

export const UpdateCustomStore = (store, id) => {
    return {
        type: "UPDATE_CUSTOM_STORE",
        customStore: store,
        gridViewId: id
    }
};

export const InitCustomStoreForChart = (store, id) => {
    return {
        type: "INIT_CUSTOM_STORE_FOR_CHART",
        customStore: store,
        dataViewId: id
    }
};

export const UpdateGridViewDefinition = (data, id) => {
    return {
        type: "UPDATE_GRIDVIEW_COLUMN_DEF",
        gridDefinition: data,
        gridViewId: id
    }
};

export const SetLeftMenuItem = (data) => {
    return {
        type: "SET_LEFT_MENU_ITEM",
        selectedLeftMenuItem: data,
    }
};

export const KeepGridViewDataById = (id) => {
    return {
        type: "KEEP_GRID_DATA_BY_ID",
        gridViewId: id
    }
};

export const KeepChartDataById = (id) => {
    return {
        type: "KEEP_CHART_DATA_BY_ID",
        dataViewId: id
    }
};

export const SetUserPermissions = (data) => {
    return {
        type: "SET_USER_PERMISSIONS",
        permissions: data
    }
};

export const SetLeopardAdminMode = (data) => {
    return {
        type: "SET_LEOPARD_ADMIN_MODE",
        data
    }
};
