import React, {Component} from 'react';
import {connect} from 'react-redux';
import LRH from '../helpers/LeopardReactHelper';
import LDH from '../helpers/LeopardDataHelper';
import {off, on} from "devextreme/events";
import {InitChartData, InitCustomStoreForChart} from './LeopardActionCreators';
import {CommonSeriesSettings, ConstantLine, Label} from 'devextreme-react/chart';
import LeopardCountdownSwitch from "../datashaping/LeopardCountdownSwitch";
import LeopardTooltipWithLink from "../datashaping/LeopardTooltipWithLink";
import $ from "jquery";
import LeopardStaticUIConfig from "./LeopardStaticUIConfig";
import {InitializePieChart} from "../helpers/LeopardPieChartHelper";
import {InitializeBarChart} from "../helpers/LeopardBarChartHelper";
import LeopardTooltipWithIcon from "../datashaping/LeopardTooltipWithIcon";
import ScrollView from 'devextreme-react/scroll-view';

class LeopardChartEngine extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // ---- For "UseStateStore => True" Only ----
            customStore: null,
            chartData: null,
            // ------------------------------------------
            chartDataResponse: null
        };

        this.uiObjectInstance = {
            chartInstance: null
        };
        this.retryConnectionCount = 0;
        this.relationships = [];
        this.relationshipsLinkedToDataView = [];
        this.zoomToVisualRange = null;
        this.disposingAllInstances = false;
        this.chartDataLength = 0;
        this.selectedParentViewData = null;
        this.selectedChartPointData = null;
        this.chartUpdateStatus = null;
    }

    setChartInstance = (ref) => {
        let that = this;
        if (ref === null || ref.instance === null) return;

        let requireRefresh = false;
        if (LDH.IsObjectNull(this.uiObjectInstance.chartInstance)) {
            requireRefresh = true;
        }
        this.uiObjectInstance.chartInstance = ref.instance;

        this.props.setChartInstance({
            instance: ref.instance,
            id: this.props.dataViewId,
            type: "chart",
            isDataView: true
        });

        that.relationships = that.props.relationships;
        let dashboardItemId = that.props.dataViewId;

        if (!LDH.IsObjectNull(dashboardItemId) && !LDH.IsValueEmpty(dashboardItemId) &&
            !LDH.IsObjectNull(that.relationships) &&
            that.relationshipsLinkedToDataView.length === 0) {
            let linkedList = LDH.GetRelationshipsByDashboardItemId(that.relationships,
                dashboardItemId);
            that.relationshipsLinkedToDataView = linkedList;
            that.uiObjectInstance.chartInstance.option("relationships", linkedList);
        }

        that.selectedParentViewData = null;
        if (!LDH.IsObjectNull(dashboardItemId) && !LDH.IsValueEmpty(dashboardItemId) &&
            !LDH.IsObjectNull(that.relationships)) {
            LeopardStaticUIConfig.Global_DashboardDataViewListeners.push({
                dashboardItemId,
                props: that.props,
                instance: ref.instance,
                callback(data) {
                    if (!LDH.IsObjectNull(that.props.definition.chartDefinition.parameters) &&
                        !LDH.IsValueEmpty(that.props.definition.chartDefinition.parameters)) {
                        let parametersCloned = LDH.DeepClone(that.props.definition.chartDefinition.parameters);

                        for (let x = 0; x < parametersCloned.length; x++) {
                            let item = parametersCloned[x];
                            if (!LDH.IsObjectNull(window["dataViewParam_" + that.props.dataViewId + "_control_" + item.parameterName])) {
                                let controlId = window["dataViewParam_" + that.props.dataViewId + "_control_" + item.parameterName];
                                data.dataFromSource[item.parameterName] = controlId;
                            }
                        }
                    }

                    for (let i = 0; i < data.features.length; i++) {
                        if (data.features[i] === "rowlink") {
                            let chartDefinition = this.props.definition.chartDefinition;
                            let defaultVR = chartDefinition.defaultVisualRange;
                            let params = chartDefinition.oDataQueryForLinkedView;
                            let visualRange = Number.MAX_VALUE;
                            that.selectedParentViewData = data.dataFromSource;

                            if (!LDH.IsObjectNull(defaultVR) && !LDH.IsValueEmpty(defaultVR)) {
                                visualRange = defaultVR;
                            }
                            that.zoomToVisualRange = visualRange;
                            if (!LDH.IsObjectNull(params) && !LDH.IsValueEmpty(params)) {
                                if (LDH.IsObjectNull(data.dataFromSource)) {
                                    this.instance.option("setBlankPostProcess", true);
                                    that.refreshChartByInstance(this.instance);
                                } else {
                                    params = LDH.ConvertArrayMacroToString(params,
                                        data.dataFromSource, null);
                                    this.instance.option("dataFromSource", data.dataFromSource);
                                    this.instance.option("customQueryParams", params);
                                    this.instance.option("setBlankPostProcess", false);
                                    this.instance.option("selectFirstRow", true);
                                    that.refreshChartByInstance(this.instance);
                                }
                            } else if ((LDH.IsObjectNull(params) || LDH.IsValueEmpty(params)) &&
                                !LDH.IsObjectNull(data.dataFromSource)) {
                                this.instance.option("dataFromSource", data.dataFromSource);
                                this.instance.option("setBlankPostProcess", false);
                                this.instance.option("selectFirstRow", true);
                                that.refreshChartByInstance(this.instance);
                            } else if ((LDH.IsObjectNull(params) || LDH.IsValueEmpty(params)) &&
                                LDH.IsObjectNull(data.dataFromSource)) {
                                this.instance.option("setBlankPostProcess", true);
                                that.refreshChartByInstance(this.instance);
                            }
                        }
                    }
                }
            });
        }

        if (requireRefresh === true) {
            this.refreshChartByInstance(ref.instance);
        }
    };

    componentWillUnmount = () => {
        this.disposingAllInstances = true;
        LRH.DisposeUIInstancesFromList(this.uiObjectInstance);

        let chartDefinition = this.props.definition.chartDefinition;
        let dataViewId = this.props.dataViewId;
        if (!LDH.IsObjectNull(chartDefinition.parameters) && chartDefinition.parameters.length > 0) {
            for (let k = 0; k < chartDefinition.parameters.length; k++) {
                let controlId = chartDefinition.parameters[k].parameterName;
                let controlName = "dataViewParam_" + dataViewId + "_control_" + controlId;
                if (!LDH.IsObjectNull(window[controlName])) {
                    delete window[controlName];
                }
            }
        }

        this.props.setChartInstance({
            instance: null,
            id: this.props.dataViewId,
            type: "chart",
            isDataView: true
        });
    };

    componentDidMount = () => {
        this.relationships = this.props.relationships;
        if (this.props.useStateStore === null || this.props.useStateStore === false) {
            this.initializeCustomStore(true);
        } else {
            this.initializeCustomStore(false);
        }
    };

    initializeCustomStore = (isDataView) => {
        let that = this;
        let dataViewId = this.props.dataViewId;
        let url = this.props.dataSourceUrl;
        let httpMethod = this.props.definition.chartDefinition.httpMethod;
        if (LDH.IsObjectNull(httpMethod) || LDH.IsValueEmpty(httpMethod)) {
            httpMethod = "get";
        }
        let serverSideQuery = this.props.definition.chartDefinition.customQueryAttributes;
        let useNewUrlQuery = false;
        let odataEngineVersion = this.props.definition.chartDefinition.odataEngineVersion;

        if (!LDH.IsObjectNull(odataEngineVersion) && odataEngineVersion === "v2") {
            useNewUrlQuery = true;
        }

        let httpDataPost = this.props.definition.chartDefinition.httpDataPost;
        let linqQuery = this.props.definition.chartDefinition.clientSideQueryEditor;
        let chartDefinition = this.props.definition.chartDefinition;

        let hasCustomQueryParams = false;
        if (!LDH.IsObjectNull(chartDefinition.oDataQueryForLinkedView) &&
            !LDH.IsValueEmpty(chartDefinition.oDataQueryForLinkedView)) {
            hasCustomQueryParams = true;
        }

        let store = LRH.InitCustomStoreForChartView(url, httpMethod, dataViewId,
            serverSideQuery, linqQuery, function (data, response) {
                that.retryConnectionCount = 0;
                that.chartDataLength = 0;

                if (!LDH.IsObjectNull(data) && that.disposingAllInstances === false) {
                    that.chartDataLength = data.length;
                    if (isDataView === true) {
                        that.props.InitChartData(data, dataViewId);
                    }
                    that.setState({chartData: data, chartDataResponse: response});

                    $("#gridViewToobarWarmingUp_" + dataViewId).hide();
                    that.props.updateWindowDimensionsRequired();
                    let instance = that.uiObjectInstance.chartInstance;

                    setTimeout(function () {
                        try {
                            if (LDH.IsObjectNull(instance) === false) {
                                instance.option("selectFirstRow", false);
                                if (that.chartUpdateStatus === "beginUpdate") {
                                    instance.endUpdate();
                                    that.chartUpdateStatus = "endUpdate";
                                }

                                let showScrollbar = chartDefinition.showScrollbar;
                                let argumentAxis = instance.getArgumentAxis();
                                if (!LDH.IsValueEmpty(that.zoomToVisualRange) &&
                                    !LDH.IsObjectNull(argumentAxis)) {
                                    if (!LDH.IsObjectNull(argumentAxis.categories)) {
                                        argumentAxis.visualRange(that.zoomToVisualRange);
                                    }
                                    that.zoomToVisualRange = null;
                                }

                                if (!LDH.IsObjectNull(showScrollbar) &&
                                    showScrollbar === "no") {
                                    instance.option("scrollBar", false);
                                } else if (!LDH.IsObjectNull(showScrollbar) &&
                                    showScrollbar === "yes") {
                                    instance.option("scrollBar", true);
                                } else if (((!LDH.IsObjectNull(showScrollbar) &&
                                            showScrollbar === "auto") ||
                                        LDH.IsObjectNull(showScrollbar)) &&
                                    !LDH.IsObjectNull(argumentAxis)) {
                                    let vr = argumentAxis.visualRange();
                                    if (!LDH.IsObjectNull(vr.categories)) {
                                        argumentAxis.visualRange(vr);
                                    }
                                }

                                off(document.getElementById(that.props.dataViewId),
                                    "dxmousewheel");

                                on(document.getElementById(that.props.dataViewId),
                                    "dxmousewheel", function (e) {
                                        e.preventDefault();
                                    });
                            }
                        } catch (ex) {
                            console.log("unknown error occurred on instance.");
                        }
                    }, 100);
                    // --------------------------------------------------------------

                    $("#gridViewToobar_" + dataViewId).hide();
                    $("#Chart_TopBar_Refresh_" + dataViewId).removeClass("leopard-ui-disabled");
                }
            }, function (error, errorText) {
                if (that.disposingAllInstances === true) {
                    return;
                }
                $(".dataview_" + dataViewId).show();
                $(".leopard-right-panel-container .leopard-loading-icon").hide();
                that.props.updateWindowDimensionsRequired();

                if (that.retryConnectionCount < 10) {
                    setTimeout(function () {
                        if (!LDH.IsObjectNull(errorText) && errorText === "retry") {
                            let instance = that.uiObjectInstance.chartInstance;
                            if (!LDH.IsObjectNull(instance)) {
                                let $parent = $("#gridViewToobar_" + dataViewId);
                                $(".toolbar-warming-up-text", $parent).show();
                                that.retryConnectionCount += 1;
                                that.refreshChartByInstance(instance);
                            }
                        }
                    }, 100);
                } else {
                    LRH.ShowToast("Unable to retrieve the data for the charts.", "error", 5000);
                    $("#gridViewToobar_" + dataViewId).hide();
                    $("#Chart_TopBar_Refresh_" + dataViewId).removeClass("leopard-ui-disabled");
                    let instance = that.uiObjectInstance.chartInstance;
                    if (!LDH.IsObjectNull(instance) && that.chartUpdateStatus === "beginUpdate") {
                        instance.endUpdate();
                        that.chartUpdateStatus = "endUpdate";
                    }
                }
            }, this, isDataView, hasCustomQueryParams, chartDefinition, httpDataPost, useNewUrlQuery);

        if (isDataView === true) {
            this.props.InitCustomStoreForChart(store, dataViewId);
        } else {
            this.setState({customStore: store});
        }
    };

// -------------------- NON-GENERIC CODE START --------------------

    genericChart_axisCustomizeText(e, templateValue) {
        if (LDH.IsValueEmpty(templateValue)) return e.valueText;
        return templateValue.replace("#value#", e.valueText);
    }

    genericChart_customizeLabel(data) {
        let part1Text = "";
        let part2Text = "";
        if (!LDH.IsObjectNull(data.chartDefinition.labelCustomTextPart1) &&
            !LDH.IsValueEmpty(data.chartDefinition.labelCustomTextPart1) &&
            data.chartDefinition.labelCustomTextPart1 !== "none") {
            part1Text = data.e[data.chartDefinition.labelCustomTextPart1];
        }
        if (!LDH.IsObjectNull(data.chartDefinition.labelCustomTextPart2) &&
            !LDH.IsValueEmpty(data.chartDefinition.labelCustomTextPart2) &&
            data.chartDefinition.labelCustomTextPart2 !== "none") {
            part2Text = data.e[data.chartDefinition.labelCustomTextPart2];
        }
        if (!LDH.IsValueEmpty(part1Text) && !LDH.IsValueEmpty(part2Text)) {
            return part1Text + ": " + part2Text;
        }
        if (!LDH.IsValueEmpty(part1Text) && LDH.IsValueEmpty(part2Text)) {
            return part1Text;
        }
        if (LDH.IsValueEmpty(part1Text) && !LDH.IsValueEmpty(part2Text)) {
            return part2Text;
        }
        return data.e.valueText;
    }

    genericChart_customizeTooltip(data) {
        let part1Text = "";
        let part2Text = "";
        if (!LDH.IsObjectNull(data.chartDefinition.tooltipCustomTextPart1) &&
            !LDH.IsValueEmpty(data.chartDefinition.tooltipCustomTextPart1) &&
            data.chartDefinition.tooltipCustomTextPart1 !== "none") {
            part1Text = data.e[data.chartDefinition.tooltipCustomTextPart1];
        }
        if (!LDH.IsObjectNull(data.chartDefinition.tooltipCustomTextPart2) &&
            !LDH.IsValueEmpty(data.chartDefinition.tooltipCustomTextPart2) &&
            data.chartDefinition.tooltipCustomTextPart2 !== "none") {
            part2Text = data.e[data.chartDefinition.tooltipCustomTextPart2];
        }
        if (!LDH.IsValueEmpty(part1Text) && !LDH.IsValueEmpty(part2Text)) {
            return {text: part1Text + ": " + part2Text};
        }
        if (!LDH.IsValueEmpty(part1Text) && LDH.IsValueEmpty(part2Text)) {
            return {text: part1Text};
        }
        if (LDH.IsValueEmpty(part1Text) && !LDH.IsValueEmpty(part2Text)) {
            return {text: part2Text};
        }
        return {text: data.e.seriesName + ": " + data.e.valueText};
    }

    // -------------------- NON-GENERIC CODE END ---------------------

    renderCommonSeriesSettingsForBarChart = (chartDefinition) => {
        let genericCommonSeriesSettingsForBarChartProps = {
            argumentField: chartDefinition.seriesArgumentField,
            valueField: chartDefinition.seriesValueField,
            type: chartDefinition.seriesTypeOfSeries,
            cornerRadius: chartDefinition.seriesStyleCornerRadius,
            barWidth: chartDefinition.seriesStyleBarWidth,
            barPadding: chartDefinition.seriesStyleBarPadding,
            ignoreEmptyPoints: chartDefinition.seriesIgnoreEmptyPoint
        };
        if (chartDefinition.seriesTypeOfSeries !== "bar") return ("");
        return (<CommonSeriesSettings {...genericCommonSeriesSettingsForBarChartProps} />);
    };

    renderSeriesHighAverageForBarChart = (chartDefinition) => {
        let genericConstantLineHighAverageProps = {
            width: 2,
            value: chartDefinition.valueAxisHighAverage,
            color: '#ff7c7c',
            dashStyle: 'dash'
        };
        if (LDH.IsValueEmpty(chartDefinition.valueAxisHighAverage)) {
            return ("");
        }
        return (
            <ConstantLine {...genericConstantLineHighAverageProps}>
                <Label text={chartDefinition.valueAxisHighAverageText}/>
            </ConstantLine>
        )
    };

    renderSeriesLowAverageForBarChart = (chartDefinition) => {
        let genericConstantLineLowAverageProps = {
            width: 2,
            value: chartDefinition.valueAxisLowAverage,
            color: '#8c8cff',
            dashStyle: 'dash'
        };
        if (LDH.IsValueEmpty(chartDefinition.valueAxisLowAverage)) {
            return ("");
        }
        return (
            <ConstantLine {...genericConstantLineLowAverageProps}>
                <Label text={chartDefinition.valueAxisLowAverageText}/>
            </ConstantLine>
        )
    };

    onDrawn = () => {
        if (this.props.dataInitializedOnControls !== undefined &&
            this.props.dataInitializedOnControls === false) {
            this.props.dataInitializedOnControlsUpdateRequest();
        }
    };

    onLegendClick = (e) => {
        try {
            let series = e.target;
            if (LDH.IsObjectNull(series) ||
                typeof series.isVisible !== "function") {
                return;
            }
            if (series.isVisible()) {
                series.hide();
            } else {
                series.show();
            }
        } catch (ex) {

        }
    };

    refreshChartByInstance = (instance) => {
        if (instance.NAME === "dxPieChart") {
            if (this.chartUpdateStatus === "endUpdate" ||
                LDH.IsValueEmpty(this.chartUpdateStatus)) {
                this.chartUpdateStatus = "beginUpdate";
                instance.beginUpdate();
            }
            instance.option("dataSource").reload();
        } else {
            let visualBounds = instance.getVisibleArgumentBounds();
            let min = visualBounds.minVisible;
            let max = visualBounds.maxVisible;
            if (this.chartUpdateStatus === "endUpdate" ||
                LDH.IsValueEmpty(this.chartUpdateStatus)) {
                this.chartUpdateStatus = "beginUpdate";
                instance.beginUpdate();
            }
            instance.option("dataSource").reload();

            if (!LDH.IsObjectNull(min) && !LDH.IsObjectNull(max)) {
                instance.zoomArgument(min, max);
            }
        }
    };

    refreshOnClick = (data) => {
        let id = this.props.dataViewId;
        if ($("#Chart_TopBar_Refresh_" + id).hasClass("leopard-ui-disabled")) {
            return;
        }
        let instance = this.uiObjectInstance.chartInstance;
        if (!LDH.IsObjectNull(instance)) {
            this.refreshChartByInstance(instance);
        }

        if (!LDH.IsObjectNull(data) && !LDH.IsObjectNull(data.refreshChildViews) &&
            data.refreshChildViews) {
            let pointData = this.selectedChartPointData;
            this.onPointSelectionChanged(pointData, true);
        }
    };

    autoRefreshCountdownOnEnd = () => {
        this.refreshOnClick();
    };

    onZoomEnd = (e) => {
        let that = this;
        let instance = that.uiObjectInstance.chartInstance;
        if (LDH.IsObjectNull(instance)) return;

        let chartDefinition = that.props.definition.chartDefinition;
        let scrollbar = chartDefinition.showScrollbar;
        if (!LDH.IsObjectNull(scrollbar) && scrollbar !== "auto") {
            return;
        }

        if (LDH.IsObjectNull(e.range) ||
            LDH.IsObjectNull(e.range.categories)) {
            return;
        }

        let show = that.chartDataLength > e.range.categories.length;
        instance.option("scrollBar", {visible: show});
    };

    chartToolbar = (chartDefinition) => {
        let that = this;
        return (
            <React.Fragment>
                <ScrollView scrollByContent={true} direction="horizontal" height={"auto"}
                            bounceEnabled={false} showScrollbar={'onHover'} scrollByThumb={true}>
                    <div className={"leopard-chart-toolbar"} style={{minHeight: "30px"}}>
                        {
                            chartDefinition.enableAutoRefresh === false ? "" :
                                <span id={"autoRefresh_" + that.props.dataViewId}
                                      className={"leopard-autorefresh-button_wrapper"}
                                      style={{display: chartDefinition.showAutoRefreshSwitch ? "block" : "none"}}>
                                     <div id={"autoRefreshCountdownControl_" + that.props.dataViewId}>
                                        <LeopardCountdownSwitch
                                            autoRefreshCountdownOnEnd={that.autoRefreshCountdownOnEnd}
                                            tooltip={"The chart will be refreshed automatically when timer counts down to 0."}
                                            autoRefreshInterval={chartDefinition.autoRefreshInterval}
                                            fieldValue={chartDefinition.enableAutoRefresh}
                                            gridViewId={that.props.dataViewId}/>
                                     </div>
                                </span>
                        }
                        <div style={{width: "100%"}}></div>
                        {
                            LRH.RenderDataViewParameters(chartDefinition.parameters, that.props.dataViewId,
                                chartDefinition, function (data) {
                                    window[data.controlName] = data.control.value;
                                    if (!LDH.IsObjectNull(data.dataShapingForQuery) &&
                                        !LDH.IsValueEmpty(data.dataShapingForQuery)) {
                                        let javascript = data.dataShapingForQuery;
                                        let dataName = "data";
                                        let dataValue = [];
                                        LDH.EvaluateJavaScriptForDataShaping(javascript,
                                            dataName, dataValue, that.props.dataViewId);
                                    }
                                    if (data.autoApplyParameterFilter) {
                                        that.refreshOnClick({
                                            gridViewId: that.props.dataViewId,
                                            refreshChildViews: true
                                        });
                                    }
                                })
                        }
                        <span id={"gridViewToobar_" + that.props.dataViewId} className="leopard-gridview-dataloading">
                        <i className="fas fa-spinner fa-pulse" style={{
                            color: "rgb(255,128,0)", fontSize: "18px"
                        }}></i>
                        <span id={"gridViewToobarWarmingUp_" + that.props.dataViewId}
                              className={"toolbar-warming-up-text"}>
                            Warming up backend process...
                        </span>
                    </span>
                        <span id={"Chart_TopBar_WarningIcon_" + that.props.dataViewId}
                              className={"leopard-maximum-data-reached-warning"}>
                         <LeopardTooltipWithIcon
                             elementId={"Chart_TopBar_WarningIcon_" + that.props.dataViewId + "_help"}
                             title={"Maximum data size reached"}
                             text={"The data retrieved from the server has reached its maximum size limit. " +
                                 "This chart only shows a portion of that data to improve the performance of Control Centre."}
                             customIcon={"fas fa-exclamation-triangle"}/>
                    </span>
                        <span style={{padding: "0 2px 0 0"}}>
                            <LeopardTooltipWithLink
                                elementId={"Chart_TopBar_Refresh_" + that.props.dataViewId}
                                labelText={"Refresh"} width={250} title={"Refresh"}
                                onClick={(e) => this.refreshOnClick({
                                    e: e,
                                    gridViewId: that.props.dataViewId,
                                    refreshChildViews: !LDH.IsObjectNull(e) &&
                                    !LDH.IsObjectNull(e.refreshChildViews) ? e.refreshChildViews : false
                                })}
                                text={"The Refresh button allows you to refresh data and repaint the chart."}/>
                        </span>
                    </div>
                </ScrollView>
            </React.Fragment>
        )
    };

    onPointClick = (e) => {
        e.target.select();
    };

    onPointSelectionChanged = (e, refreshChildView) => {
        let that = this;
        if (LDH.IsObjectNull(e) || !e.target.isSelected() || (this.props.useStateStore === null ||
            this.props.useStateStore === false ||
            LDH.IsObjectNull(this.relationshipsLinkedToDataView) ||
            this.relationshipsLinkedToDataView.length === 0)) {
            this.selectedChartPointData = null;
            if (LDH.IsObjectNull(refreshChildView) || refreshChildView === false) {
                return;
            }
        }
        this.selectedChartPointData = e;

        for (let i = 0; i < this.relationshipsLinkedToDataView.length; i++) {
            let pId = this.relationshipsLinkedToDataView[i].parentDataViewId;
            let cId = this.relationshipsLinkedToDataView[i].childDataViewId;
            let features = this.relationshipsLinkedToDataView[i].interactiveFeatures;
            let dataSourceId = this.relationshipsLinkedToDataView[i].dataSourceId;
            let rowData = LDH.IsObjectNull(e) ? {} : LDH.DeepClone(e.target.data);
            let listeners = LeopardStaticUIConfig.Global_DashboardDataViewListeners;
            let childDashboardItemId = cId.split(":")[0];
            if (childDashboardItemId === this.props.dataViewId) continue;

            if (!LDH.IsObjectNull(that.props.definition.chartDefinition.parameters) &&
                !LDH.IsValueEmpty(that.props.definition.chartDefinition.parameters)) {
                let parametersCloned = LDH.DeepClone(that.props.definition.chartDefinition.parameters);

                for (let x = 0; x < parametersCloned.length; x++) {
                    let item = parametersCloned[x];
                    if (!LDH.IsObjectNull(window["dataViewParam_" + that.props.dataViewId + "_control_" + item.parameterName])) {
                        let controlId = window["dataViewParam_" + that.props.dataViewId + "_control_" + item.parameterName];
                        rowData[item.parameterName] = controlId;
                    }
                }
            }

            for (let v = 0; v < listeners.length; v++) {
                if (listeners[v].dashboardItemId === childDashboardItemId) {
                    if (LDH.IsObjectNull(listeners[v].instance)) {
                        continue;
                    }
                    try {
                        if (!LDH.IsObjectNull(listeners[v].instance) &&
                            listeners[v].instance != "blank") {
                            listeners[v].instance.clearSelection();
                        }
                        listeners[v].callback({
                            dataFromSource: rowData,
                            parentDataViewId: pId,
                            childDataViewId: cId,
                            features,
                            dataSourceId,
                            dataViewType: "chart"
                        });
                    } catch (ex) {
                        console.log("Failed to call clearSelection.");
                    }
                }
            }
        }
    };

    render() {
        if (this.disposingAllInstances) return null;

        let that = this;
        let chartState = this.props.state.chartState;
        let currentState = this.state;
        if (this.props.useStateStore === null || this.props.useStateStore === false) {
            currentState = chartState.filter(c => {
                return c.dataViewId === this.props.dataViewId;
            });
            if (currentState !== undefined && currentState !== null && currentState.length > 0) {
                currentState = currentState[0];
            }
        }

        if (currentState === undefined || currentState === null || currentState.length === 0 ||
            currentState.customStore === undefined || currentState.customStore === null ||
            currentState.customStore.length === null) {
            return (
                <div className={"leopard-dataview-retrievingdata-text"}>
                    Retrieving data, please wait...
                </div>
            );
        }
        let chartDefinition = this.props.definition.chartDefinition;
        let genericChartProps = {
            ref: this.setChartInstance,
            dataSource: currentState.customStore,
            id: this.props.dataViewId,
            className: "leopard-chart-container",
            resolveLabelOverlapping: chartDefinition.resolveLabelOverlapping,
            redrawOnResize: chartDefinition.chartRedrawOnResize,
            negativesAsZeroes: chartDefinition.chartNegativeValuesAsZeroes,
            paletteExtensionMode: chartDefinition.chartPaletteExtMode,
            barGroupWidth: chartDefinition.chartBarGroupWitdh,
            barGroupPadding: chartDefinition.chartBarGroupPadding,
            containerBackgroundColor: chartDefinition.chartContainerBgColor,
            maxBubbleSize: chartDefinition.chartMaxBubbleSize,
            minBubbleSize: chartDefinition.chartMinBubbleSize,
            palette: chartDefinition.chartPalette,
            size: {height: "100%", width: "100%"}
        };

        let genericZoomAndPanProps = {
            allowMouseWheel: chartDefinition.chartAllowMouseWheel,
            allowTouchGestures: chartDefinition.chartAllowTouchGuestures,
            dragToZoom: chartDefinition.chartDragToZoom
        };

        let genericValueAxisProps = {
            valueType: chartDefinition.valueAxisValueType,
            allowDecimals: chartDefinition.valueAxisAllowDecimals,
            autoBreaksEnabled: chartDefinition.valueAxisEnableAutoBreak,
            maxAutoBreakCount: chartDefinition.valueAxisMaxAutoBreakCount,
            maxValueMargin: chartDefinition.valueAxisMaxValueMargin,
            customizeText: chartDefinition.valueAxisCustomizeText,
            endOnTick: chartDefinition.valueAxisEndOnTick,
            inverted: chartDefinition.valueAxisInvertAxis,
            showZero: chartDefinition.valueAxisShowZero,
            type: chartDefinition.valueAxisType,
            position: chartDefinition.valueAxisPosition,
            min: (LDH.IsObjectNull(chartDefinition.valueAxisMinimumValue) ||
                LDH.IsValueEmpty(chartDefinition.valueAxisMinimumValue))
                ? 0 : chartDefinition.valueAxisMinimumValue,
            max: (LDH.IsObjectNull(chartDefinition.valueAxisMaximumValue) ||
                LDH.IsValueEmpty(chartDefinition.valueAxisMaximumValue))
                ? undefined : chartDefinition.valueAxisMaximumValue,
            logarithmBase: (LDH.IsObjectNull(chartDefinition.valueAxisLogarithmBase) ||
                LDH.IsValueEmpty(chartDefinition.valueAxisLogarithmBase))
                ? 10 : parseInt(chartDefinition.valueAxisLogarithmBase)
        };

        let genericArgumentAxisProps = {
            valueType: chartDefinition.argAxisValueType,
            allowDecimals: chartDefinition.argAxisAllowDecimals,
            autoBreaksEnabled: chartDefinition.argAxisEnableAutoBreak,
            maxAutoBreakCount: chartDefinition.argAxisMaxAutoBreakCount,
            maxValueMargin: chartDefinition.argAxisMaxValueMargin,
            customizeText: chartDefinition.argAxisCustomizeText,
            endOnTick: chartDefinition.argAxisEndOnTick,
            inverted: chartDefinition.argAxisInvertAxis,
            showZero: chartDefinition.argAxisShowZero,
            type: chartDefinition.argAxisType,
            position: chartDefinition.argAxisPosition
        };

        let genericTooltipProps = {
            argumentFormat: chartDefinition.tooltipArgumentFormat,
            arrowLength: chartDefinition.tooltipArrowLength,
            color: chartDefinition.tooltipColor,
            enabled: chartDefinition.tooltipEnabled,
            location: chartDefinition.tooltipLocation,
            opacity: chartDefinition.tooltipOpacity,
            paddingLeftRight: chartDefinition.tooltipPaddingLeftRight,
            paddingTopBottom: chartDefinition.tooltipPaddingTopBottom
        };

        let genericExportProps = {
            backgroundColor: chartDefinition.chartBgColorForExport,
            margin: chartDefinition.chartMarginForExport,
            enabled: chartDefinition.chartEnableExport,
        };

        let genericScrollbarProps = {
            position: chartDefinition.chartScrollbarPosition,
            offset: chartDefinition.chartScrollbarOffset
        };

        let genericSeriesBasicProps = {
            argumentField: chartDefinition.seriesArgumentField,
            type: chartDefinition.seriesTypeOfSeries,
            hoverMode: chartDefinition.seriesHoverMode,
            selectionMode: chartDefinition.seriesSelectionMode,
            ignoreEmptyPoints: chartDefinition.seriesIgnoreEmptyPoint,
            axis: chartDefinition.seriesAxisBinding,
            barWidth: chartDefinition.seriesStyleBarWidth,
            barPadding: chartDefinition.seriesStyleBarPadding,
            cornerRadius: chartDefinition.seriesStyleCornerRadius,
            dashStyle: chartDefinition.seriesStyleDashStyle,
            innerColor: chartDefinition.seriesStyleInnerColor,
            selectionStyle: {color: "#ec2e7a"}
        };

        let genericSeriesLabelProps = {
            visible: chartDefinition.labelVisibility,
            alignment: chartDefinition.labelAlignment,
            argumentFormat: chartDefinition.labelArgumentFormat,
            backgroundColor: chartDefinition.labelBgColor,
            format: chartDefinition.labelLabelFormat,
            horizontalOffset: chartDefinition.labelHorizontalOffset,
            verticalOffset: chartDefinition.labelVerticalOffset,
            position: chartDefinition.labelPosition,
            rotationAngle: chartDefinition.labelRotationAngle,
            showForZeroValues: chartDefinition.labelShowForZero
        };

        let genericSeriesPointProps = {
            visible: chartDefinition.pointVisibility,
            color: chartDefinition.pointColor,
            hoverMode: chartDefinition.pointHoverMode,
            selectionMode: chartDefinition.chartPointSelectionMode,
            size: chartDefinition.pointSizeOfPoint,
            symbol: chartDefinition.pointCustomSymbol
        };

        let genericLegendBasicProps = {
            visible: chartDefinition.legendVisibility,
            columnCount: chartDefinition.legendColumnCount,
            rowCount: chartDefinition.legendRowCount,
            hoverMode: chartDefinition.legendHoverMode,
            markerSize: chartDefinition.legendMarkerSize,
            orientation: chartDefinition.legendOrientation,
            itemTextPosition: chartDefinition.legendItemTextPosition,
            position: chartDefinition.legendPosition,
            itemsAlignment: chartDefinition.legendItemsAlignment,
            horizontalAlignment: chartDefinition.legendHorizontalAlignment,
            verticalAlignment: chartDefinition.legendVerticalAlignment,
            backgroundColor: chartDefinition.legendBgColor,
            columnItemSpacing: chartDefinition.legendColumnItemSpacing,
            rowItemSpacing: chartDefinition.legendRowItemSpacing,
            paddingLeftRight: chartDefinition.legendPaddingLeftRight,
            paddingTopBottom: chartDefinition.legendPaddingTopBottom,
            margin: chartDefinition.legendMargin
        };

        let genericSmallValuesGroupingProps = {
            topCount: chartDefinition.seriesSmallValueGroupingTopCount,
            mode: chartDefinition.seriesSmallValueGroupingMode
        };

        // -------------------- NON-GENERIC CODE START --------------------
        if (this.props.chartType === "bar-chart" || this.props.chartType === "line-chart" ||
            this.props.chartType === "spline-chart" || this.props.chartType === "area-chart" ||
            this.props.chartType === "scatter-chart") {
            return (
                <React.Fragment>
                    {that.chartToolbar(chartDefinition)}
                    {InitializeBarChart(genericChartProps, chartDefinition, genericZoomAndPanProps, genericValueAxisProps,
                        genericArgumentAxisProps, genericTooltipProps, genericScrollbarProps, genericSeriesBasicProps,
                        genericSeriesLabelProps, genericSeriesPointProps, genericLegendBasicProps,
                        genericExportProps, this)}
                </React.Fragment>
            );
        }

        if (this.props.chartType === "doughnut-chart") {
            return (
                <React.Fragment>
                    {that.chartToolbar(chartDefinition)}
                    {InitializePieChart(genericChartProps, chartDefinition, genericZoomAndPanProps,
                        genericTooltipProps, genericScrollbarProps, genericSeriesBasicProps, genericSeriesLabelProps,
                        genericSeriesPointProps, genericSmallValuesGroupingProps, genericLegendBasicProps,
                        genericExportProps, this)}
                </React.Fragment>
            );
        }
        // -------------------- NON-GENERIC CODE END ---------------------

        return null;
    }
}

const RetrieveDataFromReducer = (state) => {
    return {state};
};

const SendDataToReducer = (dispatch) => {
    return {
        InitChartData: (data, id) => {
            dispatch(InitChartData(data, id));
        },
        InitCustomStoreForChart: (store, id) => {
            dispatch(InitCustomStoreForChart(store, id));
        }
    };
};

export default connect(RetrieveDataFromReducer, SendDataToReducer)(LeopardChartEngine);
